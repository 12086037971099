
import { FileAwsData } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { EventBus } from "@/services/event-bus";

@Component({ components: { videoPlayer } })
export default class VPlayer extends Vue {
  @Prop(Object) readonly video!: FileAwsData;
  @Prop(String) readonly src!: string;
  @Prop(String) readonly poster!: string;

  @Ref("player") readonly obPlayer!: InstanceType<typeof videoPlayer>;

  mounted(): void {
    // EventBus.on("show.video", () => {
    //   if (this.player) {
    //     this.player.play();
    //   }
    // });

    EventBus.on("hide.video", () => {
      if (this.player) {
        this.player.pause();
      }
    });
  }

  beforeDestroy(): void {
    // EventBus.off("show.video");
    EventBus.off("hide.video");
  }

  get player(): VideoJsPlayer {
    return this.obPlayer ? this.obPlayer.player : false;
  }

  get playerOptions(): VideoJsPlayerOptions {
    return {
      language: "es",
      autoplay: false,
      // height: this.height,
      aspectRatio: this.$_.get(
        this.video.metadata,
        "display_aspect_ratio",
        "16:9"
      ),
      sources: [
        {
          src: this.src,
          type: this.video.mimetype,
        },
      ],
      poster: this.poster,
      html5: {
        vhs: {
          withCredentials: true,
        },
      },
    };
  }
}
